import revive_payload_client_4sVQNw7RlN from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_W86eSGFqyZ from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import plugin_KlVwwuJRCL from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import plugin_iGLjBbor9q from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import i18n_yfWm7jX06p from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import log_client_pZLvF3s8cQ from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/@scayle/storefront-nuxt/dist/runtime/plugin/log.client.mjs";
import shop_D5MPhOSKAF from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/node_modules/@scayle/storefront-nuxt/dist/runtime/plugin/shop.mjs";
import reportPlugin_7SFdTkRWY0 from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/modules/serverSideTracking/runtime/reportPlugin.ts";
import _01_tracking_OPtsZEwIsT from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/01.tracking.ts";
import _02_routeChangeTrackingObserver_client_pbkB4McNNd from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/02.routeChangeTrackingObserver.client.ts";
import dompurify_qvTkTTURiH from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/dompurify.ts";
import featureToggle_6huLtR7Thw from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/featureToggle.ts";
import fimLocalePath_p4aWujMMAh from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/fimLocalePath.ts";
import maska_UHaKf2z1iQ from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/maska.ts";
import onetrust_script_i5ROHACheV from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/onetrust-script.ts";
import syte_zBX7ifTOHQ from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/syte.ts";
import toast_ysMjUcU7eJ from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/toast.ts";
import tooltip_Di9mlKVbnI from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/tooltip.ts";
import validation_30a3L3OexF from "/builds/aboutyou/cloud-agency/shop-application/fim-sfa/plugins/validation.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_client_W86eSGFqyZ,
  plugin_KlVwwuJRCL,
  plugin_iGLjBbor9q,
  i18n_yfWm7jX06p,
  log_client_pZLvF3s8cQ,
  shop_D5MPhOSKAF,
  reportPlugin_7SFdTkRWY0,
  _01_tracking_OPtsZEwIsT,
  _02_routeChangeTrackingObserver_client_pbkB4McNNd,
  dompurify_qvTkTTURiH,
  featureToggle_6huLtR7Thw,
  fimLocalePath_p4aWujMMAh,
  maska_UHaKf2z1iQ,
  onetrust_script_i5ROHACheV,
  syte_zBX7ifTOHQ,
  toast_ysMjUcU7eJ,
  tooltip_Di9mlKVbnI,
  validation_30a3L3OexF
]