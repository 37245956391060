import { type Product } from '@scayle/storefront-nuxt'
import type { StoryblokTrackingContent } from '~/@types/storyblok'

type PromotionTrackingContent = StoryblokTrackingContent & {
  products?: Product[]
}

const trackPromotion = (
  event: TrackingEvent,
  trackableObject: PromotionTrackingContent,
  track: (event: TrackingEvent, payload: TrackingPayload) => any,
) => {
  if (!Object.values(trackableObject).some(Boolean)) {
    return
  }
  const { products } = trackableObject
  track(event, {
    ...(products && { products }),
    promotion_id: trackableObject._uid,
    promotion_name: trackableObject.promotion_name,
    creative_name: trackableObject.creative_name,
    creative_slot: window.scrollY.toString(),
    index: parseInt(`${trackableObject.index}`),
  })
}

const usePromotionEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any,
) => ({
  trackPromotionView: (trackableObject: PromotionTrackingContent) => {
    trackPromotion('FielmannBasic_EC_PromotionView', trackableObject, track)
  },
  trackPromotionClick: (trackableObject: PromotionTrackingContent) => {
    trackPromotion('FielmannBasic_EC_PromotionClick', trackableObject, track)
  },
})

export default usePromotionEvents
