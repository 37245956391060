<template>
  <div
    class="flex w-full flex-col justify-center bg-neutral-15 px-4 pb-11 pt-6 md:flex-row md:px-4 md:py-[35px] lg:px-9"
  >
    <div class="my-[5px] flex flex-wrap items-center justify-center">
      <div class="pl-[5px] text-xl font-medium">
        {{ $t('footer.store_finder.heading') }}
      </div>
    </div>
    <div
      class="my-[5px] flex flex-col flex-wrap items-center justify-center gap-y-[10px] xs:flex-row md:gap-0"
    >
      <div class="mr-2 flex items-center md:ml-6">
        <input
          v-model="search"
          class="mr-2.5 h-[34px] w-[200px] rounded border border-neutral-30 py-1.5 pl-3 pr-8 placeholder:text-neutral-50 md:w-[270px] lg:w-[434px]"
          :placeholder="$t('footer.store_finder.heading')"
          @keyup.enter="onSearch"
        />
        <FimButton
          class="-ml-16 border-none"
          type="link"
          is-small
          :aria-label="$t('search.submit')"
          :disabled="fetching"
          @click="onSearch"
        >
          <template #icon>
            <LazyIconFielmannSearch class="size-5" />
          </template>
        </FimButton>
      </div>
      <FimButton
        type="primary"
        class="h-[34px] !py-[2px]"
        :disabled="fetching"
        @click="onLocate"
      >
        <template #icon>
          <LazyIconFielmannPin class="size-5" />
        </template>
        {{ $t('footer.store_finder.button') }}
      </FimButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const { search, onLocate, onSearch, fetching } = useStoreFinder()
</script>
