import {
  type Product,
  getLatestCategory,
  type Product as BapiProduct,
} from '@scayle/storefront-nuxt'

type ListingMetaData = {
  name?: string
  id?: string
  content_name?: string
  page_type?: string
  page_type_id?: string
}

const collectProductListItems = (
  items: Product[],
  options: { listName: string; listId: string },
) => {
  return items.map(
    (product: any, idx: number): ProductListData => ({
      product,
      list: {
        name: options.listName,
        id: options.listId,
        index: idx + 1,
      },
    }),
  )
}

const useProductEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload, options?: any) => any,
  { localePath, $helpers }: any,
) => ({
  trackSelectItem: (
    product: Product,
    category?: {
      categoryId: number | string
      categoryName: string
    },
    listingMetaData?: ListingMetaData,
    index?: number,
    source?: string,
    search?: SearchResultData,
  ) => {
    const payload: any = {
      product,
      destinationUrl: localePath($helpers.getProductDetailRoute(product)),
      destination: `product|${product.id}`,
    }

    if (category) {
      payload.category = {
        name: category?.categoryName || '',
        id: category?.categoryId.toString() || '',
      }
    }

    if (listingMetaData) {
      payload.list = {
        name: listingMetaData.name,
        id: listingMetaData.id,
        index,
      }
    }

    if (source) {
      payload.source = source
    }

    if (search) {
      payload.search = search
    }

    track(
      'FielmannBasic_EC_SelectItem',
      {
        ...payload,
      },
      search && { overridePageType: 'searchresults' },
    )
  },
  trackViewItemList: (
    items: any[],
    listingMetaData: ListingMetaData,
    index = -1,
    paginationOffset = 0,
    source?: string,
    search?: SearchResultData,
  ) => {
    const itemsToTrack =
      items?.map(
        (product: Product & { index: number }): ProductViewData => ({
          product,
          category: {
            name: getLatestCategory(product.categories)?.categoryName || '',
            id:
              getLatestCategory(product.categories)?.categoryId.toString() ||
              '',
          },
          list: {
            name: listingMetaData.name || '',
            id: listingMetaData.id || '',
            index:
              index > -1
                ? index
                : paginationOffset +
                  (product.index + paginationOffset > 0 ? 1 : 0), // TODO refactor: check this logic
          },
          destinationUrl: localePath(
            $helpers.getProductDetailRoute(product as Product),
          ),
          destination: `product|${product.id}`,
          source:
            source ||
            `category|${
              getLatestCategory(product?.categories)?.categoryId.toString() ||
              ''
            }` ||
            '',
        }),
      ) || []

    track(
      'FielmannBasic_EC_ItemViewList',
      {
        items: itemsToTrack,
        search,
      },
      search && { overridePageType: 'searchresults' },
    )
  },
  trackViewItem: (product: BapiProduct, options?: any) => {
    track(
      'FielmannBasic_EC_ItemView',
      {
        product,
      },
      options,
    )
  },
  collectProductListItems,
})

export default useProductEvents
