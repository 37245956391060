/* eslint sonarjs/cognitive-complexity: 1 */

import { type Category } from '@scayle/storefront-nuxt'
import { type NuxtApp } from 'nuxt/app'
import { PRIVACY_PAGE_SLUGS } from '../constants/pageType'
import { type RouteLocation } from '#vue-router'
export { slugify } from '@scayle/storefront-nuxt'

const getCategoryPath = (category: Category) => {
  if (!category) {
    return
  }
  return `${category.path}`
}

export const normalizePathRoute = (path: string) => {
  return path.startsWith('/') ? path : `/${path}`
}

type Link =
  | 'search'
  | 'wishlist'
  | 'basket'
  | 'product'
  | 'store'
  | 'stores'
  | 'citypage'
  | 'checkout'
  | 'signin'
  | 'signup'
  | 'signout'
  | 'account'
  | 'order'
  | 'branchOrder'
  | 'branchOrderDetail'
  | 'orderDetail'
  | 'orderStatus'
  | 'user'
  | 'home'
  | 'ropoBasket'
  | 'ropoPlp'
  | 'appointments'
  | 'success'

export type LinkList = Record<
  Link,
  {
    name: string
    path: string
    isProtected?: boolean
    parameter?: string
    query?: { [key: string]: string }
  }
>

export const routeList: LinkList = {
  home: { name: 'index', path: '/' },
  search: { name: 'search', path: '/search/' },
  wishlist: { name: 'wishlist', path: '/wishlist/' },
  basket: { name: 'basket', path: '/basket/' },
  product: { name: 'p-slug', path: '/p/' },
  stores: { name: 'niederlassungen', path: '/niederlassungen/' },
  store: {
    name: 'niederlassungen-city-id',
    path: '/niederlassungen/:city/:id/',
  },
  citypage: {
    name: 'niederlassungen-stadt-county-city',
    path: '/niederlassungen/:county/:city/',
  },
  signin: { name: 'signin', path: '/signin/' },
  signup: { name: 'signin', path: '/signin', query: { register: 'true' } },
  signout: { name: 'signout', path: '/signout/' },
  account: { name: 'account', path: '/account/', isProtected: true },
  checkout: { name: 'checkout', path: '/checkout/', isProtected: true },
  order: {
    name: 'account-orders',
    path: '/account/orders/',
    isProtected: true,
  },
  branchOrder: {
    name: 'account-branch-order',
    path: '/account/branch-order/',
    isProtected: true,
  },
  branchOrderDetail: {
    name: 'account-branch-order-id',
    path: '/account/branch-order',
    parameter: 'id',
    isProtected: true,
  },
  user: { name: 'account-user', path: '/account/user/', isProtected: true },
  orderStatus: {
    name: 'service-auftragsstatus',
    path: '/service/auftragsstatus/',
  },
  orderDetail: {
    name: 'account-orders-detail-id',
    path: '/account/order/',
    parameter: 'id',
    isProtected: true,
  },
  ropoBasket: {
    name: 'ropo-basket',
    path: '/anprobeliste/',
  },
  ropoPlp: {
    name: 'ropo-plp',
    path: '/anprobevorort/',
  },
  appointments: {
    name: 'account-appointments',
    path: '/account/appointments',
    isProtected: true,
  },
  success: {
    name: 'success',
    path: '/success',
    parameter: 'cbd',
  },
} as const

export const getStoreDetailRoute = (cityName: string) => {
  return {
    name: routeList.stores.name,
    params: {
      slug: cityName,
    },
  }
}

export const getSearchRoute = (term: string) => {
  return {
    name: 'search',
    query: { term },
  }
}

export const getProtectedRouteList = (exclude?: string): string[] => {
  return Object.entries(routeList)
    .filter(([key, value]) => value.isProtected && exclude !== key)
    .map(([, value]) => value.path)
}

/* @legacy? */
export const getBaseUrl = (
  $currentShop: NuxtApp['$currentShop'],
  $config: NuxtApp['$config'],
) => {
  const baseUrl = new URL($config.public.baseUrl) // always returns AT domain
  const domain = $currentShop.domain

  const url = new URL(
    domain.includes('://') ? domain : `${baseUrl.protocol}//${domain}`,
  )

  url.port = baseUrl.port

  return url.toString()
}

export const normalizeUrl = (
  path: string | undefined | Array<string | undefined>,
  base?: string | URL,
) => {
  const paths = Array.isArray(path) ? path : [path]

  const basePath =
    !paths.some((p) => p?.includes('://')) && base ? new URL(base).pathname : ''

  const input = [basePath, ...paths]
    .filter(Boolean)
    .join('/')
    .replaceAll(/(?<!:)\/+/gm, '/')

  return new URL(input, base)
}

export const trailingSlash = (path: string) => {
  if (path.includes('://')) {
    return path
  }

  const url = new URL(path, 'https://example.com')

  if (!url.pathname.endsWith('/')) {
    url.pathname += '/'
  }

  return url.toString().replace('https://example.com', '')
}

export const getCanonicalRoute = (baseUrl: string, path: string): string => {
  try {
    const url = new URL(
      path,
      /:\/\//.test(baseUrl) ? baseUrl : `https://${baseUrl}`,
    )
    url.pathname = (url.pathname + '/').replace(/\/+/gm, '/')

    const validKeys = ['page']

    if (url.searchParams.get('page') === '1') {
      url.searchParams.delete('page')
    }

    for (const key of url.searchParams.keys()) {
      if (!validKeys.includes(key)) {
        url.searchParams.delete(key)
      }
    }

    return url.toString().replace(/[?&]$/, '')
  } catch (error) {
    return ''
  }
}

export const isNumericIdRoute = (routePath: string) => {
  const pathWithoutSlashes = routePath.replaceAll('/', '')
  return /^\d+$/.test(pathWithoutSlashes)
}

export const isNumericProductIdRoute = (routePath: string) => {
  return /\/p\/[0-9]+\//.test(routePath)
}

export const getRouteParam = (
  params: RouteLocation['params'],
  key: string,
  defaultValue?: string,
) => {
  const value = params?.[key]

  if (Array.isArray(value)) {
    return value?.[0] ?? defaultValue
  }

  return value ?? defaultValue
}

export const getQueryParam = (
  query: RouteLocation['query'],
  key: string,
  defaultValue?: string,
) => {
  const regexp = /\[(\d+)?\]$/
  const [, indexRaw] = key.match(/\[(\d+)?\]$/) || []
  const index = parseInt(indexRaw, 10)
  const isArrayKey = regexp.test(key)

  const value = isArrayKey
    ? query?.[key] ||
      query?.[key.replace(regexp, '[]')] ||
      query?.[key.replace(regexp, '')]
    : query?.[key] || query?.[`${key}[]`]

  if (Array.isArray(value)) {
    return value?.[index || 0] ?? defaultValue
  }

  if (value === null || value === undefined || index > 0) {
    return defaultValue
  }

  return value
}

export const isPrivacyPage = (slug: string = '') =>
  PRIVACY_PAGE_SLUGS.includes(slug)

export { getCategoryPath }
