import type { SbLink, Sbmultilink } from '../types/storyblok'
import { getNormalizedLocale } from '~/utils/locale'
import { type ObjectWith } from '~/utils/types'

type LinkType = Sbmultilink['linktype']

export const isInternalLink = (linktype: LinkType) => linktype === 'story'

const addSlashes = (url: string, internal?: boolean) => {
  if (!internal) {
    return url
  }

  // Link To Home
  if (!url) {
    return '/'
  }

  return [
    !url.startsWith('/') && !url.includes('://') && '/',
    url,
    !url.endsWith('/') && '/',
  ]
    .filter(Boolean)
    .join('')
    .replace(/(https?:\/\/)|(\/){2,}/g, '$1$2')
}

export const normalizeUrlPath = (
  url: string,
  $currentShop: ObjectWith<{ locale: string; cmsBaseFolder: string }>,
  internal?: boolean,
) => {
  if (internal) {
    const locale = getNormalizedLocale($currentShop.locale).toLowerCase()
    const regexp = new RegExp(
      `^(https?://[^/]+)?((?:/${$currentShop.cmsBaseFolder}/)|(?:/${locale}/))`,
      'i',
    )

    return addSlashes(url.replace(regexp, '$1/'), true)
  }

  return url
}

export const buildTargetURL = (
  url: string,
  $currentShop: ObjectWith<{ locale: string; cmsBaseFolder: string }>,
  idTarget?: string,
  isInternalLink?: boolean,
) => {
  if (idTarget) {
    return `${normalizeUrlPath(url, $currentShop, isInternalLink)}#${idTarget}`
  }

  return normalizeUrlPath(url, $currentShop, isInternalLink)
}

export const ctaURLHandler = (
  ctaLink: ObjectWith<{ cta_url?: Sbmultilink; id_target?: string }>,
  $currentShop: ObjectWith<{ locale: string; cmsBaseFolder: string }>,
) => {
  return (
    resolveSbMultilinkUrl(ctaLink.cta_url, $currentShop, ctaLink.id_target) ||
    ''
  )
}

const isLinkType = (input: string | undefined): input is LinkType =>
  !!input && ['asset', 'url', 'email', 'story'].includes(input)

export const getLinkType = (
  blok: MaybeRefOrGetter<SbLink | undefined>,
): LinkType | undefined => {
  const _blok = toValue(blok)
  if (isLinkType(_blok?.cta_url?.linktype)) {
    return _blok?.cta_url?.linktype
  }
}

export const getLinkTarget = (blok: MaybeRefOrGetter<SbLink | undefined>) => {
  return toValue(blok)?.cta_target_blank ? '_blank' : '_self'
}

export const resolveSbMultilinkUrl = (
  link: Sbmultilink | undefined,
  $currentShop: ObjectWith<{ locale: string; cmsBaseFolder: string }>,
  target?: string,
): string | undefined => {
  if (link?.linktype === 'url' || link?.linktype === 'asset') {
    return link.cached_url || link.url
  }

  if (link?.linktype === 'email') {
    return link.email ? `mailto:${link.email}` : undefined
  }

  if (link?.linktype === 'story' && link.cached_url) {
    const href = normalizeUrlPath(link.cached_url, $currentShop, true)
    const anchor = target ? `#${target}` : ''

    return `${href}${anchor}`
  }
}

export const resolvSbLink = (
  blok: MaybeRefOrGetter<SbLink | undefined>,
  $currentShop: MaybeRefOrGetter<
    ObjectWith<{ locale: string; cmsBaseFolder: string }>
  >,
) => {
  const entry = toValue(blok)

  if (!entry) {
    return undefined
  }

  return resolveSbMultilinkUrl(
    entry.cta_url,
    toValue($currentShop),
    entry.id_target,
  )
}

export const isValidLink = (
  link: MaybeRefOrGetter<Sbmultilink | undefined>,
) => {
  const multiLink = toValue(link)

  if (multiLink?.linktype === 'email' && multiLink.email) {
    return true
  }

  return Boolean(multiLink && 'cached_url' in multiLink && multiLink.cached_url)
}
